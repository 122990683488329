<template>
  <list-card
    ref="refList"
    :items="items"
    :entity-type="'object'"
    :list-actions="listActions"
    :group-actions="groupActions"
    :is-filter-active="isFilterActive"
    :sorting="sorting"
    :current-sort-by="currentSortBy"
    :title="title"
    @click:sort="handleSort"
    @click:filter-icon="handleFilterOpen"
    @active="handleActive"
    @selectedItemIds="handleSelectedItemIds"
  >
    <template #item="{select, ...props}">
      <object-list-item
        v-bind="props"
        :actions="getItemActions(props.item)"
        :to="getItemRoute(props.item)"
        @select="select"
        @click:favourite="toggleFavourite(props.item)"
      />
    </template>
    <template #toolbar-selection:extra-items>
      <v-btn icon color="primary">
        <favourite-icon />
      </v-btn>
    </template>
  </list-card>
</template>

<script>
import ListCard from '@/components/list-card';
import ObjectListItem from './ObjectListItem';
import { useObjectListActions } from './listActions';

import { computed, ref, toRefs, watch } from '@vue/composition-api';

import { useMap } from '@/compositions/map';
import { sortTypes, useSortBy } from '@/compositions/sortBy';
import { useObjectMenu } from '@/modules/objects/compositions/objectMenu';
import { objectService } from '@/modules/objects/api';
import { useObjectGroups } from '@/modules/object-groups/compositions/object-groups';
import { useObjects } from '../../compositions/objects';
import {
  genMenuItemAddToGroup,
  genMenuItemAccess,
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemDownload,
  genMenuItemMove,
  genMenuItemMute,
  genMenuItemEnable,
  genMenuItemRemoveFromGroup,
  hideIfEmpty
} from '@/utils/list-generators';
import { linkedObjectService } from '@/modules/linked-objects/api';
import { usePopup } from '@/compositions/popup';
import downloadBlob from '@/utils/downloadBlob';
import useMedia from '@/compositions/media';
import router from '@/router';
import {
  OBJECT_LIST_FILTER,
  useObjectFilters
} from '@/modules/objects/compositions/object-filters';
import { useFilter } from '@/compositions/filter';
import { useTypes } from '@/modules/object-types/compositions/types';
import { useGeotags } from '@/modules/geotags/compositions/geotags';

export default {
  name: 'Objects',
  components: {
    ListCard,
    ObjectListItem
  },
  props: {
    groupId: {
      type: [String, undefined],
      default: undefined
    }
  },
  setup(props, { root }) {
    const refList = ref(null);

    const {
      markers: { setMarkers, selectMarker, unselectMarker, selectMarkers }
    } = useMap();

    const { getNotificationsHistory, getObjectPropertiesHistory } = useMedia();

    const popup = usePopup();

    const { groupId } = toRefs(props);

    const { groupById, objectGroupList } = useObjectGroups();

    const { list } = useObjects();

    const groupList = computed(() => {
      if (!groupId.value) return list.value;
      return list.value.filter(i => i.objectGroupIds.includes(groupId.value));
    });

    const title = computed(() => {
      return `${!groupId.value ? 'Objects' : groupById(groupId.value)?.name}`;
    });

    const getItemRoute = item => ({
      name: 'object_card',
      params: {
        ...root.$route.params,
        objectId: item.id
      },
      query: root.$route.query
    });

    const sorting = [
      {
        id: 'name',
        sortType: sortTypes.abc,
        sortKey: 'name',
        title: 'Name'
      },
      {
        id: 'status',
        sortType: sortTypes.boolean,
        sortKey: 'enabled',
        title: 'Status'
      },
      {
        id: 'alarm',
        sortType: sortTypes.boolean,
        sortKey: 'alarmed',
        title: 'Alarm'
      },
      {
        id: 'color',
        sortType: sortTypes.abc,
        sortKey: 'color',
        title: 'Color'
      },
      {
        id: 'favourites',
        sortType: sortTypes.boolean,
        sortKey: 'favourite',
        title: 'Favourites'
      }
    ];

    const currentSortBy = ref(sorting[0].id);

    const selectedItems = ref([]);

    const getItemActions = object => {
      return useObjectMenu({ object });
    };

    const clearSelection = () => {
      refList.value && refList.value.clearSelection();
    };

    const groupActions = computed(() => [
      genMenuItemAccess(() => {
        popup.open({
          component: () => import('@/components/popup/EditRightsGroup.vue'),
          props: {
            objectIds: selectedItems.value,
            many: true,
            onConfirm: () => {
              clearSelection();
            }
          }
        });
      }),
      genMenuItemMute(() => {}, {
        disabled: true
      }),
      genMenuItemDisable(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Disable these objects?',
            onConfirm: () => {
              linkedObjectService.massiveUpdate(
                selectedItems.value.map(item => ({ id: item, enabled: false }))
              );
              clearSelection();
            }
          }
        });
      }),
      genMenuItemEnable(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Enable these objects?',
            onConfirm: () => {
              linkedObjectService.massiveUpdate(
                selectedItems.value.map(item => ({ id: item, enabled: true }))
              );
              clearSelection();
            }
          }
        });
      }),
      genMenuItemMove(() => {
        router.push({
          name: 'object_moving_group',
          params: {
            objectIds: selectedItems.value
          }
        });
      }),
      genMenuItemAddToGroup(() => {
        popup.open({
          component: () =>
            import('@/modules/objects/ui/object-list/PopupAddToGroups.vue'),
          props: {
            selectedItemIds: selectedItems.value
          }
        });
        clearSelection();
      }),
      ...hideIfEmpty(!!groupId.value, [
        genMenuItemRemoveFromGroup(async () => {
          selectedItems.value.forEach(itemId => {
            objectService.unlinkByObjectIds(groupId.value, itemId);
          });
          clearSelection();
        })
      ]),
      genMenuItemDownload(
        () => {
          popup.open({
            component: () =>
              import('@/components/history-list-card/HistoryDateRangePopup'),
            props: {
              showDataShape: true,
              onSubmit: async range => {
                const data = {
                  ids: selectedItems.value,
                  from: `${range.from} 00:00`,
                  to: `${range.to} 23:59`,
                  shape: range.shape,
                  include_fragments: true
                };
                getObjectPropertiesHistory(data)
                  .then(res => {
                    downloadBlob(
                      res.data,
                      `PixelMonitor_PropertiesHistory_${range.from}-${range.to}.csv`,
                      res.data.type
                    );
                  })
                  .catch(() => {})
                  .finally(() => {});
                clearSelection();
              }
            }
          });
        },
        { title: 'Download history' }
      ),
      genMenuItemDownload(
        () => {
          popup.open({
            component: () =>
              import('@/components/history-list-card/HistoryDateRangePopup'),
            props: {
              onSubmit: async range => {
                const data = {
                  ids: selectedItems.value,
                  from: `${range.from} 00:00`,
                  to: `${range.to} 23:59`
                };
                getNotificationsHistory(data).then(res => {
                  downloadBlob(
                    res.data,
                    `PixelMonitor_NotificationsHistory_${range.from}-${range.to}.csv`,
                    res.data.type
                  );
                });
                clearSelection();
              }
            }
          });
        },
        {
          title: 'Download notifications'
        }
      ),
      genMenuItemDelete(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Delete these objects completely?',
            onConfirm: () => {
              linkedObjectService.massiveDelete(
                selectedItems.value.map(item => ({ id: item }))
              );
              clearSelection();
            }
          }
        });
      })
    ]);

    const { list: typeList } = useTypes();
    const { list: geotags } = useGeotags();

    const filters = useObjectFilters({
      objectTypes: typeList,
      objectGroups: objectGroupList,
      geotags
    });

    const { filteredItems, isFilterActive, handleFilterOpen } = useFilter(
      groupList,
      filters,
      OBJECT_LIST_FILTER
    );

    const { sortType, sortKey, sortedItems } = useSortBy(
      filteredItems,
      sorting[0].sortKey,
      sorting[0].sortType
    );

    const handleSort = e => {
      currentSortBy.value = e.id;
      sortType.value = e.sortType;
      sortKey.value = e.sortKey;
    };

    const toggleFavourite = ({ id, favourite }) => {
      objectService.setFavourite(id, !favourite);
    };

    const { listActions } = useObjectListActions();

    watch(
      () => filteredItems.value,
      (items = []) => {
        setMarkers(items);
      },
      {
        immediate: true
      }
    );

    const handleActive = ({ id, active }) => {
      active ? selectMarker(id) : unselectMarker(id);
    };

    const handleSelectedItemIds = ids => {
      selectedItems.value = ids;
      selectMarkers(ids);
    };

    return {
      getItemRoute,
      items: sortedItems,
      getItemActions,
      listActions,
      groupActions,
      filters,
      sorting,
      currentSortBy,
      handleSort,
      toggleFavourite,
      handleActive,
      handleSelectedItemIds,
      title,
      refList,
      isFilterActive,
      handleFilterOpen
    };
  }
};
</script>

<style></style>
