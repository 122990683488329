<template>
  <v-autocomplete
    ref="autocomplete"
    class="mx-4 my-2 elevation-4"
    style="max-width: 100%"
    hide-details
    solo
    flat
    append-icon="mdi-magnify"
    :search-input="query"
    :items="!isEntered ? items : []"
    item-text="name"
    item-value="id"
    placeholder="Search"
    hide-no-data
    no-filter
    @update:search-input="setQuery"
    @keydown.enter="handleKeydownEnter"
  >
    <template v-slot:prepend-inner>
      <v-app-bar-nav-icon @click.stop="toggle" />
    </template>
    <template v-slot:append>
      <v-icon @click="onIconClick" v-text="icon" />
      <div v-if="$vuetify.breakpoint.mobile" class="d-flex mr-1">
        <v-divider vertical class="mx-3" />
        <notifications class="mx-2" />
      </div>
    </template>
    <template v-slot:item="{ item }">
      <v-divider v-if="item.id === 'divider'" />
      <v-list-item v-else @click="() => handleItemClick(item.to)">
        <v-list-item-avatar>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.name" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import useMenu from '@/layouts/menu/useMenu';
import {
  computed,
  onMounted,
  ref,
  toRefs,
  watch,
  nextTick
} from '@vue/composition-api';
import Notifications from '@/layouts/map/headerMenu/notifications/index';
import { useGlobalSearch } from '@/compositions/globalSearch';
export default {
  name: 'SearchBar',
  components: {
    Notifications
  },
  props: {
    entityType: {
      type: String,
      default: 'object'
    }
  },
  setup(props, { root, emit }) {
    const { entityType } = toRefs(props);
    const { toggle } = useMenu();
    const autocomplete = ref(null);
    const isEntered = ref(false);

    const {
      query,
      entityKey,
      entitySearchList,
      commonSearchList
    } = useGlobalSearch();

    watch(
      () => entityType.value,
      () => {
        entityKey.value = entityType.value;
      },
      { immediate: true }
    );

    function setQuery(v) {
      // fix unexpected null after mount component
      if (v === null) return;
      query.value = v;
      isEntered.value = false;
      emit('query', query.value);
    }

    const icon = computed(() => {
      return query.value ? 'mdi-close' : 'mdi-magnify';
    });

    const items = computed(() => {
      let isNeededDivider = true;
      if (!entitySearchList.value.length || !commonSearchList.value.length) {
        isNeededDivider = false;
      }
      const entitySearchHeader =
        entitySearchList.value.length >= 5
          ? 'Showing up to 5 first results'
          : 'Showing all results';
      const commonSearchHeader =
        commonSearchList.value.length >= 5
          ? 'Showing up to 5 first results'
          : 'Showing all results';
      return [
        ...(entitySearchList.value.length
          ? [{ header: entitySearchHeader }]
          : []),
        ...entitySearchList.value.slice(0, 5),
        ...(isNeededDivider ? [{ id: 'divider', disabled: true }] : []),
        ...(commonSearchList.value.length
          ? [{ header: commonSearchHeader }]
          : []),
        ...commonSearchList.value.slice(0, 5)
      ];
    });

    const onIconClick = () => {
      if (query.value) {
        setQuery('');
      }
    };

    const handleItemClick = to => {
      root.$router.push(to);
    };

    const handleKeydownEnter = () => {
      setQuery(query.value);
      isEntered.value = true;
    };

    const getStorageKey = entityKey => `${entityKey}_QUERY`;

    onMounted(async () => {
      await nextTick();
      if (entityKey.value) {
        const storedQuery = localStorage.getItem(
          getStorageKey(entityKey.value)
        );
        if (storedQuery) {
          setQuery(storedQuery);
          autocomplete.value.focus();
          isEntered.value = true;
        }
      }
    });

    return {
      toggle,
      icon,
      onIconClick,
      items,
      query,
      setQuery,
      handleItemClick,
      handleKeydownEnter,
      autocomplete,
      isEntered
    };
  }
};
</script>

<style></style>
