export const filterEUI = (item, query) => {
  const devices = item?.objectsToObjectsByObject1Id || [];
  const devicesWithEUI = devices.filter(item => item?.object2?.eui) || [];

  if (!devices.length || !devicesWithEUI.length) {
    return false;
  }

  return devicesWithEUI.find(item =>
    item.object2.eui.toLowerCase().includes(query?.toLowerCase())
  );
};
